import { SALEPLAN_DISTRIBUTOR_URL, SALEPLAN_URL, USER_URL } from '@Env/environment';
import { IResponse } from '@Mesh/core/models/IResponse';
import { BonusConfiguration } from '@Mesh/core/models/bonus/bonus-configuration';
import { Outlet } from '@Mesh/core/models/outlet';
import { IAutoOrderQueryParams } from '@Mesh/core/models/query-params';
import { IUserData } from '@Mesh/core/models/user';
import { IAutoOrder } from '@Mesh/pages/auto-orders/create-auto-order-types';
import { IPendingOrder, IUpdatePendingOrder } from '@Mesh/pages/pending-orders/IPendingOrder';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OrdersService {

  constructor(private http: HttpClient) {
  }

  readonly baseUrl = `${SALEPLAN_URL}/v1/orders`;

  getOrdersRecommendedConfigs(params?: Partial<IAutoOrderQueryParams>): Observable<IResponse<IAutoOrder[]>> {
    return this.http.get<IResponse<IAutoOrder[]>>(`${SALEPLAN_DISTRIBUTOR_URL}/v1/orders/configuration/recommended`, { params: <HttpParams>params });
  }

  createOrdersRecommendedConfigs(data: IAutoOrder): Observable<IResponse<BonusConfiguration[]>> {
    return this.http.post<IResponse<BonusConfiguration[]>>(`${SALEPLAN_DISTRIBUTOR_URL}/v1/orders/configuration/recommended`, data);
  }

  deleteOrdersRecommendedConfigs(orderId: number): Observable<IResponse<BonusConfiguration[]>> {
    return this.http.delete<IResponse<BonusConfiguration[]>>(`${SALEPLAN_DISTRIBUTOR_URL}/v1/orders/configuration/recommended/${orderId}`);
  }

  getOrderRecommendedConfigs(id: number): Observable<IAutoOrder> {
    return this.http.get<IAutoOrder>(`${SALEPLAN_DISTRIBUTOR_URL}/v1/orders/configuration/recommended/${id}`);
  }

  getMotivationPrograms(): Observable<BonusConfiguration[]> {
    return this.http.get<BonusConfiguration[]>(`${SALEPLAN_URL}/v1/bonus/configuration`);
  }

  getPendingOrders(addressId: number): Observable<IResponse<IPendingOrder[]>> {
    return this.http.get<IResponse<IPendingOrder[]>>(`${SALEPLAN_URL}/v1/cart/${addressId}/delayed`);
  }

  updatePendingOrders(addressId: number, data: IUpdatePendingOrder): Observable<IResponse<IPendingOrder[]>> {
    return this.http.put<IResponse<IPendingOrder[]>>(`${SALEPLAN_URL}/v1/cart/${addressId}/delayed`, data);
  }

  getUserData(userIds: number[]): Observable<IResponse<IUserData[]>> {
    const params: Partial<any> = { userIds };
    return this.http.get<IResponse<IUserData[]>>(`${USER_URL}/user`, {params: <HttpParams>params})
  }

  getUserAddress(addressId: number): Observable<Outlet> {
    return this.http.get<Outlet>(`${USER_URL}/address/${addressId}`);
  }
}
