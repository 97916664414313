import { Injectable } from '@angular/core';
import { getAutoOrderList, getAutoOrdersSuccess, getAutoOrderSuccess, getAutoOrder, deleteAutoOrder, createAutoOrder, createAutoOrdersSuccess, toggleLoading, getMotivationProgramsSuccess, getMotivationPrograms } from './auto-order.actions';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { switchMap, tap } from 'rxjs/operators';
import { OrdersService } from '@Mesh/core/services/api/orders/orders.service';
import { Router } from '@angular/router';

@Injectable()
export class AutoOrderEffects {
  constructor(
    private actions: Actions,
    private ordersService: OrdersService,
    private router: Router) {
  }

  getAutoOrderList$ = createEffect(
    () => this.actions.pipe(
      ofType(getAutoOrderList),
      switchMap(({ params }) => this.ordersService.getOrdersRecommendedConfigs({ ...params })),
      switchMap(autoOrders => [getAutoOrdersSuccess({ autoOrders })])
    )
  );

  createAutoOrder$ = createEffect(
    () => this.actions.pipe(
      ofType(createAutoOrder),
      switchMap(({ autoOrder }) => this.ordersService.createOrdersRecommendedConfigs(autoOrder)),
      switchMap(() => [toggleLoading({ loading: false })]),
      tap(() => this.router.navigate(['/auto-orders/configurations']))
    )
  );

  getAutoOrder$ = createEffect(
    () => this.actions.pipe(
      ofType(getAutoOrder),
      switchMap(({ id }) => this.ordersService.getOrderRecommendedConfigs(id)),
      switchMap(autoOrder => [getAutoOrderSuccess({ autoOrder })]),
    )
  );

  getMotivationProgramsSuccess$ = createEffect(
    () => this.actions.pipe(
      ofType(getMotivationPrograms),
      switchMap(() => this.ordersService.getMotivationPrograms()),
      switchMap(motivationPrograms => [getMotivationProgramsSuccess({ motivationPrograms })]),
    )
  );

  deleteAutoOrder$ = createEffect(
    () => this.actions.pipe(
      ofType(deleteAutoOrder),
      switchMap(({ id }) => this.ordersService.deleteOrdersRecommendedConfigs(id)),
      switchMap(() => [getAutoOrderList({ params: { pageNumber: 0 } })])
    )
  );
}
