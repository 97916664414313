import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromAgent from '../../reducers/agent/agent.reducer';

export const getAgentState = createFeatureSelector<fromAgent.State>('agent');
export const getAgentVisits = createSelector(
    getAgentState,
    fromAgent.getAgentVisits
);

export const selectCreatingAgentVisitsStatus = createSelector(
    getAgentState,
    fromAgent.getCreatingAgentVisitsStatus
);
export const selectCreatingAgentVisitsError = createSelector(
    getAgentState,
    fromAgent.getCreatingAgentVisitsError
);