import { Deserializable } from "@Mesh/core/models/deserializable";
import { Permission } from "../../pages/employees/types/permission.type";
import { Client } from "./client";
import { AgentUser } from "./agent-user";
import { UserDistributorRole } from "./user-distributor-role";

interface AdditionsType {
  id: number | null;
  name: string | null;
}

export class User implements Deserializable {
  id?: number;
  clientId?: number;
  role?: string;
  username?: string;
  status?: any;
  type?: string;
  name: string;
  surname: string;
  avatar?: string;
  division?: AdditionsType;
  position?: AdditionsType;
  client?: Client;

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }

  get short_name(): string {
    const result = [];
    if (this.name) {
      result.push(this.name.charAt(0));
    }
    if (this.surname) {
      result.push(this.surname.charAt(0));
    }
    return result.length ? result.join(" ") : "";
  }

  get full_name(): string {
    const result = [];
    if (this.name) {
      result.push(this.name);
    }
    if (this.surname) {
      result.push(this.surname);
    }
    return result.length ? result.join(" ") : "";
  }
}

export interface IUserInfo {
  accessTokenExpires: number;
  refreshTokenExpires: number;
  accessToken: string;
  refreshToken: string;
  user: IUserData;
}

export interface IUserData {
  id: number;
  name: string;
  phone: string;
  status: string;
  type: string;
  username: string;
  avatar: string;
  email: string;
  possibleClientId: number;
  possibleEmail: string;
  possiblePhone: string;
  position: string;
  client: string;
  permissions: string[];
  addresses: string;
  distributorRoles: DistributorRole[];
}

export interface UsersSearchParams {
  distrIds: number[];
  ids: number[];
  search: string;
  agentPositionIds: number[];
  agentDivisionIds: number[];
  agentWithDismissal: boolean;
  addressCountryIds: number[];
  addressRegionIds: number[];
  addressCityIds: number[];
  addressPostalCodeStreetIds: number[];
  addressPermissions: string[];
  pageSize: number;
  pageNumber: number;
  sort: string[];
  addressClientIds: number[];
  addressIds: number[];
}

export interface IUser {
  username: string;
  avatar: string;
  email: string;
  client: Client;
  id: number;
  surname: string;
  name: string;
  patronymic: string;
  phone: string;
  status: string;
  type: string;
  position: string;
  sex: string;
  birthday: string;
  createdAt: string;
  updatedAt: string;
  schoolExternalId: string;
  timezone: string;
  distributorRoles: UserDistributorRole[];
  agentUsers: AgentUser[];
  clientAddressPermissions: ClientAddressPermission[];
  iqretailId: number;
}

export interface ClientAddressPermission {
  userId: number;
  clientId: number;
  addressId: number;
  permissions: string[];
}

export interface UserCreatePayload {
  phone: string;
  username: string;
  surname: string;
  name: string;
  patronymic: string;
  email: string;
  timezone: string;
  sex: string;
  birthday: string;
}

export interface UserUpdatePayload {
  phone: string;
  username: string;
  surname: string;
  name: string;
  patronymic: string;
  email: string;
  timezone: string;
  sex: string;
  birthday: string;
  id: number;
  password: string;
  status: string;
}

export interface UserRemoveParams {
  id: number;
}

export enum UserStatusType {
  DRAFT = "DRAFT",
  ACTIVE = "ACTIVE",
  NEW = "NEW",
  INACTIVE = "INACTIVE",
}

export interface DistributorRole {
  name?: string;
  id: number;
  distributor: UserDistributor;
  role: IUserDistributorRole;
  owner: boolean;
}

export interface UserDistributor {
  id: number;
  parent: any;
  externalId: any;
  name: string;
  description: any;
  image: any;
  slug: string;
}

export interface IUserDistributorRole {
  id: number;
  name: string;
  permissions: UserDistributorPermission[];
}

export interface UserDistributorPermission {
  id: number;
  entityType: string;
  permission: string;
}

export enum UserDistributorPermissionType {
  VIEW = "VIEW_RECORD",
  CREATE = "CREATE_RECORD",
  UPDATE = "UPDATE_RECORD",
  DELETE = "DELETE_RECORD",
}

export enum UserDistributorRoleType {
  ADMIN = "ADMIN",
  OWNER = "OWNER",
  TYPE_1 = "TYPE_1",
  TYPE_2 = "TYPE_2",
  ASM = "ASM",
}

export enum UserType {
  ADMIN = "ADMIN",
  AGENT = "AGENT",
  EXCHANGER = "EXCHANGER",
  OWNER = "OWNER",
}
// OWNER, MANAGER, EMPLOYEE, ADMIN, EXCHANGER, AGENT, SUPERVISOR, REGION_MANAGER

export enum UserStatus {
  NEW = "NEW",
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
  DRAFT = "DRAFT",
  UNKNOWN = "UNKNOWN",
}

export const UserStatusMap = {
  [UserStatus.NEW]: {
    type: UserStatus.NEW,
    title: "Новый",
    color: "#FFD600",
  },
  [UserStatus.ACTIVE]: {
    type: UserStatus.ACTIVE,
    title: "Активен",
    color: "#00D0B3",
  },
  [UserStatus.INACTIVE]: {
    type: UserStatus.INACTIVE,
    title: "Неактивен",
    color: "#FF5254",
  },
  [UserStatus.DRAFT]: {
    type: UserStatus.DRAFT,
    title: "Черновик",
    color: "#FF8C4D",
  },
  // [UserStatus.UNKNOWN]: {
  //   type: UserStatus.UNKNOWN,
  //   title: 'Неизветсно',
  //   color: '#FF5254'
  // },
};

export const UserTypeMap = {
  [UserType.AGENT]: {
    type: UserType.AGENT,
    title: "Торговый агент",
  },
  [UserType.ADMIN]: {
    type: UserType.ADMIN,
    title: "Супер адмнинистратор",
  },
  [UserType.EXCHANGER]: {
    type: UserType.EXCHANGER,
    title: "Сотрудник дистрибьютора",
  },
};

export const AllowWriteUserTypeList = [
  UserDistributorRoleType.ADMIN,
  UserDistributorRoleType.OWNER,
  UserDistributorRoleType.TYPE_1,
  UserDistributorRoleType.ASM,
];
