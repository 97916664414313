import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SALEPLAN_DISTRIBUTOR_URL } from '../../../../../environments/environment';
import { objectToParams } from '../../../../shared/helpers/object.helpers';
import { EquipmentSearchParams, Equipment, EquipmentCreatePayload } from '../../../models/equipment';
import { IPageableList } from '../../../models/pageable';

@Injectable({
  providedIn: 'root'
})
export class EquipmentService {
  constructor(private readonly http: HttpClient) { }

  searchEquipment(searchEquipmentParams: Partial<EquipmentSearchParams>): Observable<IPageableList<Equipment>> {
    const params = objectToParams(searchEquipmentParams);
    return this.http.get<IPageableList<Equipment>>(`${SALEPLAN_DISTRIBUTOR_URL}/v1/equipment/filter`, { params });
  }

  loadEquipmentById(id: number): Observable<Equipment> {
    return this.http.get<Equipment>(`${SALEPLAN_DISTRIBUTOR_URL}/v1/equipment/${id}`);
  }

  createEquipment(payload: EquipmentCreatePayload): Observable<Equipment> {
    return this.http.post<Equipment>(`${SALEPLAN_DISTRIBUTOR_URL}/v1/equipment`, payload);
  }

  updateEquipment(id: number, payload: EquipmentCreatePayload): Observable<Equipment> {
    return this.http.put<Equipment>(`${SALEPLAN_DISTRIBUTOR_URL}/v1/equipment/${id}`, payload);
  }

  removeEquipment(id: number): Observable<any> {
    return this.http.delete<any>(`${SALEPLAN_DISTRIBUTOR_URL}/v1/equipment/${id}`);
  }
}
